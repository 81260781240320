import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import logo from '../../../images/supprelinla-logo.svg'
import './header.scss'

const Header = (props) => (
  <div className="outer-container">
    <div className="inner-container">
      <Grid fluid>
        <Row>
          <Col xs={12} md={5}>
            <img
              src={logo}
              alt="SUPPRELIN LA Logo"
              title="SUPPRELIN LA Logo"
              className="logo"
            />
          </Col>
          <Col xs={12} md={7}>
            <h1>The Caregiver Insights Survey</h1>
          </Col>
        </Row>
      </Grid>
    </div>
  </div>
)

export default Header
