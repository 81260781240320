import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import SourceEmitter from '../../lib/emitter'

class ISIContent extends React.Component {

  constructor(props) {
    super(props)
    this.handleCollapseClick = this.handleCollapseClick.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
  }

  handleCollapseClick = () => {
    SourceEmitter.emit('ISICollapseEvent', true)
  }

  handleExpandClick = () => {
    SourceEmitter.emit('ISIExpandEvent', true)
  }

  render(){
    const { isiExpanded, isCollapsed } = this.props;

    return (
      <div className="isi-content">

        <div className="inner-container">
          {(() => {
                        if (isCollapsed) {
                            return (
                              <button className={`isi-see-more expand ${this.props.isiExpanded? 'hideMe' : 'showMe'}`} onClick={this.handleExpandClick}><span className="link-see-more">Expand</span><span className="arrow-see-more">&#9652;</span></button>
                            )
                        } else {
                          return (
                            <button className={`isi-see-more collapse ${this.props.isiExpanded? 'hideMe' : 'showMe'}`} onClick={this.handleCollapseClick}><span className="link-see-more">Collapse</span><span className="arrow-see-more">&#9662;</span></button>
                          )
                        }
                    })()}
          {!isiExpanded && (

            <AnchorLink href="#isi_jump" offset='65' className={`isi-see-more ${this.props.isiExpanded? 'hideMe' : 'showMe'}`}>
              <span className="link-see-more">See More</span>
              <span className="arrow-see-more">&#9652;</span>
            </AnchorLink>

          )}
          <a name="ISI_ANCHOR"/>
          <h6>IMPORTANT SAFETY <br className='hide-in-desktop-isi'/> INFORMATION ABOUT <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span></h6>
          <ul>
            <li className="first"><p><strong><span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> should not be taken if your child is</strong> allergic to gonadotropin releasing hormone (GnRH), GnRH agonist medicines, or any ingredients in the <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> implant, or is pregnant or becomes pregnant. <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> can cause birth defects or loss of the baby. If your child becomes pregnant, call your doctor.</p></li>
            <li className="first"><p>In the first week of treatment, <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> can cause an increase in some hormones. During this time, you may notice more signs of puberty in your child, including light vaginal bleeding and breast enlargement in girls. Within 4 weeks of treatment, you should see signs in your child that puberty is stopping.</p></li>
            <li className="first"><p>Some people have had implant breakage during the removal process.</p></li>
            <li className="first"><p>Some people who had <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> placed in their arm have had the implant come through the skin (extrusion). <strong>Call your child's doctor right away if the <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> implant comes through the skin.</strong></p></li>
            <li className="first"><p>Some people taking GnRH agonists like <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> have had new or worsening mental (psychiatric) problems including depression. Mental (psychiatric) problems may include emotional symptoms such as crying, irritability, restlessness (impatience), anger, and acting aggressive. <strong>Before your child receives <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span>, tell the doctor if they have a history of mental problems. Call your child's doctor right away if your child has any new or worsening mental symptoms or problems while taking <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span></strong>.</p></li>
            <li><p>Some people taking GnRH agonists like <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> have had seizures. The risk of seizures may be higher in people who have a history of seizures, epilepsy, brain or brain vessel (cerebrovascular) problems or tumors, or who are taking a medicine that has been connected to seizures such as bupropion or selective serotonin reuptake inhibitors (SSRIs). Seizures have also happened in people who have not had any of these problems. <strong>Before your child receives <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span>, tell the doctor if they have a history of these problems, as well as all the medicines they take. Call your child's doctor right away if your child has a seizure while taking <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span></strong>.</p></li>
            <li><p>Increased pressure in the fluid around the brain can happen in children taking GnRH agonist medicines, including <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span>. <strong>Call your child's doctor right away if your child has any of the following symptoms during treatment with <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span>: headache, eye problems, including blurred vision, double vision, and decreased eyesight, eye pain, ringing in the ears, dizziness, nausea.</strong></p></li>
            <li><p>Your child's doctor should do tests to make sure your child has CPP before treating with <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span>. <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> lasts for 12 months. One implant will give the medicine for 12 months. After 12 months, <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> must be removed. The doctor may insert a new <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> at this time to continue treatment.</p></li>
            <li><p><span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> is placed under the skin of the inside of the upper arm. The doctor will numb the arm of your child, make a small cut, and then place <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> under the skin. The cut may be closed with stitches or surgical strips and covered with a pressure bandage.</p></li>
            <li><p>Your child should keep the arm clean and dry and should not swim or bathe for 24 hours after receiving the <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> implant. The bandage can be removed after 24 hours. <strong>Do not</strong> remove any surgical strips. They will fall off on their own in a few days. Your child should avoid heavy play or exercise that uses the implanted arm for 7 days. You and your child should routinely check the insertion site until the cut is healed. After the cut has healed, your child can go back to his or her normal activities. The doctor will give you complete instructions.</p></li>
            <li><p>Keep all scheduled visits to the doctor. Your child's doctor will do regular exams and blood tests to check for signs of puberty. Sometimes the doctor will have to do special tests, such as ultrasound or MRI, if the <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> implant is difficult to find under your child's skin.</p></li>
            <li><p>The most common side effects of <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> are skin reactions at the place where the implant is inserted. These reactions may include pain, redness, bruising, soreness, and swelling in and around the implant site. Call your child's doctor if your child has bleeding, redness, or severe pain where the implant was inserted.</p></li>
            <li />
          </ul>
          <p>You are encouraged to report negative side effects of prescription drugs to the FDA. Visit <a className="link_medwatch" target="_blank" href="http://www.fda.gov/Safety/MedWatch/">MedWatch</a> or call <span className="block_nowrap">1-800-FDA-1088.</span></p>
          <h6>WHAT IS <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span>?</h6>
          <ul className="first">
            <li><p><span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> is an implanted gonadotropin releasing hormone (GnRH) medicine used for the treatment of children with central precocious puberty (CPP).</p></li>
            <li><p>It is not known if <span className="block_nowrap">SUPPRELIN<sup>®</sup> LA</span> is safe and effective in children under 2 years of age.</p></li>
          </ul>
          <p className="last"><strong>Click for full <a href="https://www.endodocuments.com/SUPPRELIN-LA/PI" target="_blank">Prescribing Information</a>, including <a href="https://endodocuments.com/SUPPRELIN-LA/MG" target="_blank">Medication Guide</a>.</strong></p>
        </div>
      </div>
    )
  }
}

export default ISIContent
