import React from 'react'
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import EventEmitter from '../../lib/emitter'

import './mobile-nav.scss'

const handleHImportantClick = (toggleMenu) => {
  toggleMenu();
}

const handleHCPClick = (toggleMenu) =>  {
  toggleMenu();
  EventEmitter.emit('OPEN_INTERSTITIAL', {url: '/hcp'})
}


const MobileNav = (props) => (
  <div className="off-canvas">
    <Row>
      <Col xs={12}>
          <Row>
            <Col xs={12} md={4}>
              <div className="nav-items-container">
                <h5>About CPP</h5>
                <ul>
                  <li><Link activeClassName="active" to='/patients/what-is-central-precocious-puberty/'>What Is CPP?</Link></li>
                  <li><Link activeClassName="active" to='/patients/why-treat-central-precocious-puberty/'>Why Treat CPP?</Link></li>
                  <li><Link activeClassName="active" to='/patients/what-are-my-childs-treatment-options/'>Treatment Options</Link></li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="nav-items-container">
                  <h5>About SUPPRELIN<sup>&reg;</sup> LA</h5>
                  <ul>
                    <li><Link activeClassName="active" to='/patients/what-is-supprelin-la/'>About SUPPRELIN<sup>&reg;</sup> LA</Link></li>
                    <li><Link activeClassName="active" to='/patients/5-good-reasons-to-ask/'>Why SUPPRELIN<sup>&reg; </sup> LA?</Link></li>
                    <li><Link activeClassName="active" to='/patients/insertion-and-after-care/'>Insertion &amp; After-care</Link></li>
                    <li><Link activeClassName="active" to='/patients/removal-and-reinsertion/'>Removal &amp; Reinsertion</Link></li>
                    <li><Link activeClassName="active" to='/patients/potential-side-effects/'>Potential Side Effects</Link></li>
                  </ul>
                </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="nav-items-container">
                  <h5>Copay Assistance</h5>
                  <ul>
                    <li><Link activeClassName="active" to='/patients/shares-savings-program/'>Savings</Link></li>
                    <li><Link activeClassName="active" to='/patients/support-center/'>Support Center &amp; <br/> Getting Started</Link></li>
                  </ul>
                </div>
            </Col>
          </Row>
          <Row between={'md'}>
            <Col xs={12} md={4}>
                <div className="nav-items-container">
                    <h5>Resources</h5>
                    <ul>
                      <li><Link activeClassName="active" to='/patients/caregiver-resources/'>Videos Explaining <span className='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span></Link></li>
                      <li><Link activeClassName="active" to='/patients/downloads-support/'>Download Patient &amp; <br/>Caregiver Resources</Link></li>
                      <li><Link activeClassName="active" to='patients/caregiver-signup/'>Sign up for More Information</Link></li>
                    </ul>
                  </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="nav-items-container">
                    <ul>
                      <li><AnchorLink offset={props.headerHeight} onClick={() => { handleHImportantClick(props.toggleMenu) }} href='#isi_jump'>Important Safety <br/>Information</AnchorLink></li>
                      <li><a onClick={() => {handleHCPClick(props.toggleMenu)}}  href='#'>Visit HCP Site</a></li>
                    </ul> 
                  </div>
              </Col>
          </Row>
      </Col>
    </Row>
  </div>
)

export default MobileNav
